import React from 'react';
import { useHistory } from 'react-router-dom';
import { MuiBox, MuiTypography } from 'components/MUI';
import { Avatar, Menu, MenuItem, IconButton, CardHeader } from '@mui/material';
import { KeyboardArrowDownIcon } from 'helpers/Icons';
import Theme from 'Theme';
import { getImageUrl } from 'helpers/helpers';

function UserMenu(props) {

    const { handleLogout, user } = props;
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const stringAvatar = (name) => {
        if (!name) return '';
        const strName = name.slice(0, 1).toUpperCase();
        return strName;
    }

    return (
        <MuiBox className='userMenuMainWrap'>
            <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{
                    ml: 2,
                    textAlign: 'left',
                    background: 'transparent !important',
                    '& .MuiTouchRipple-root': {
                        display: 'none',
                    }
                }}
            >
                <CardHeader
                    avatar={
                        <Avatar src={getImageUrl(user?.profile_image)} sx={{ width: 40, height: 40, fontSize: '18px' }}>
                            {stringAvatar(user?.full_name) || ''}
                        </Avatar>
                    }
                    action={<KeyboardArrowDownIcon />}
                    title={
                        <MuiTypography variant='h5' sx={{
                            margin: '0 0 2px 0',
                            fontFamily: "'Open Sans', sans-serif",
                            fontWeight: 600,
                        }}>
                            {user ? user.full_name : ''}
                        </MuiTypography>
                    }
                    subheader={
                        <MuiTypography variant='body5' fontWeight={500}>{user?.company_name || ''}</MuiTypography>
                    }
                    sx={{
                        padding: 0,
                        '& .MuiCardHeader-avatar': {
                            marginRight: '10px',
                        },
                        '& .MuiCardHeader-content': {
                            textTransform: 'capitalize'
                        },
                        '& .MuiCardHeader-action': {
                            alignSelf: 'auto',
                            marginLeft: '8px',
                            '& svg': {
                                color: Theme.palette.secondary.dark,
                                fontSize: 18,
                            }
                        }
                    }}
                />
            </IconButton>

            <Menu
                className='commonDropMenuWrap'
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'none',
                        border: 'solid 1px #e3e4e5',
                        boxShadow: 'none',
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: -9,
                            right: 12,
                            borderTop: '15px solid #fff',
                            borderLeft: '12px solid transparent',
                            borderRight: '12px solid transparent',
                            transform: 'rotateZ(-180deg) translateY(0) translateX(0)',
                            zIndex: 1,
                        },
                        '&:after': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: -10,
                            right: 14,
                            borderTop: '10px solid #e3e4e5',
                            borderLeft: '10px solid transparent',
                            borderRight: '10px solid transparent',
                            transform: 'rotateZ(-180deg) translateY(0) translateX(0)',
                            [Theme.breakpoints.up(1800)]: {
                                top: -11,
                                right: 15,
                            },
                        },
                        '& li': {
                            color: Theme.palette.secondary.dark,
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 400,
                            padding: '8px 45px',
                            width: '100%',
                            fontFamily: "'Open Sans', sans-serif",
                            transition: '0.4s',
                            '&:hover': {
                                backgroundColor: '#c4c4c461',
                                color: Theme.palette.secondary.dark,
                                transition: '0.4s',
                            },
                            '& svg': {
                                color: Theme.palette.secondary.dark,
                                fontSize: 20,
                            },
                            '& .MuiListItemIcon-root': {
                                minWidth: 30,
                            },
                            '& a': {
                                color: Theme.palette.secondary.dark,
                                padding: 0,
                                fontWeight: 400,
                                width: '100%',
                                '&:hover': {
                                    color: Theme.palette.secondary.dark,
                                }
                            }
                        }
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => history.push('/profile')}>
                    My Profile
                </MenuItem>
                {['free', 'trialing', 'none', 'canceled'].includes(user.subscription_status) === false &&
                    <MenuItem onClick={() => history.push('/my-subscription')}>
                        My Subscription
                    </MenuItem>
                }
                <MenuItem onClick={() => history.push('/theme')}>
                        Theme setting
                    </MenuItem>
                <MenuItem onClick={() => history.push('/plans')}>
                    Plans
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    Logout
                </MenuItem>
            </Menu>
        </MuiBox>
    )
}

export default UserMenu