import { withStyles } from '@mui/styles';
import { SuccessIcon } from 'Assets/Images';
import { MuiBox, MuiButton, MuiDialog, MuiDialogTitle, MuiIconButton, MuiTypography } from 'components/MUI';
import styles from 'global-styles';
import { CloseIcon } from 'helpers/Icons';
import React from 'react'
import Theme from 'Theme';

const DialogTitle = withStyles(styles)((props) => {
    const { children, onClose, ...other } = props;
    return (
        <MuiDialogTitle {...other}>
            <MuiTypography p={0}>{children}</MuiTypography>
            {onClose ? (
                <MuiIconButton aria-label="close" onClick={onClose} sx={{
                    position: 'absolute',
                    right: 5,
                    top: 5,
                }}>
                    <CloseIcon />
                </MuiIconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function ConfirmModal(props) {
    const { message, onModalClose, open, handleSubmit } = props;
    return (
        <MuiDialog onClose={onModalClose} aria-labelledby="customized-dialog-title" open={open} p={3}>
            <MuiBox p={2}>
                <DialogTitle id="customized-dialog-title" onClose={onModalClose}></DialogTitle>

                <MuiBox align="center" p={2}>
                    <img src={SuccessIcon} style={{ width: 70 }} />
                    <MuiTypography variant='h3' mt={2} mb={1}>Are You Sure?</MuiTypography>
                    <MuiTypography component='p'>{message}</MuiTypography>
                </MuiBox>

                <MuiBox mt={3} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '15px',
                    '& button': {
                        minWidth: 100,
                    }
                }}>
                    <MuiButton background='grey' onClick={onModalClose}>Cancel</MuiButton>
                    <MuiButton onClick={handleSubmit}>Yes</MuiButton>
                </MuiBox>
            </MuiBox>
        </MuiDialog>
    )
}

export default ConfirmModal