export const storage = process.env.NODE_ENV === 'development' ? sessionStorage : localStorage;

export default class Storage {
    static LoadState = () => {
        try {
            const localState = storage.getItem('state') || {};

            if (localState === null) {
                return this.initializeState();
            }

            return JSON.parse(localState);

        } catch (error) {

        }
    }

    static SaveState = (state) => {
        try {
            storage.clear();
            const localState = JSON.stringify(state);
            storage.setItem('state', localState);
        } catch (err) {
            return err;
        }
    }

    static initializeState = () => {
        return {};
    }
}