import axios from "axios";
import { API_URL } from "./configs";
import ToastService from "helpers/ToastService";
import { setApiCallProgress } from "redux/Actions/ui";
import { setLogout } from "redux/Actions/login";
import { storage } from "redux/Storage";

axios.defaults.baseURL = API_URL;

axios.interceptors.request.use(
    config => {
        const localState = storage.getItem('state') || {};
        if (localState !== null) {
            const myStorage = JSON.parse(localState);
            config.headers.xTenantId = myStorage?.login?.user?.tenant?.id || '';
        }
        import('redux/Store').then(Store => {
            Store.default.dispatch(setApiCallProgress(true));
        })
        return config;
    },
    error => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    resp => {
        import('redux/Store').then(Store => {
            if ((resp.status === 200 || resp.status === 201) && resp.data.message) {
                ToastService.success(resp.data.message);
            }
            Store.default.dispatch(setApiCallProgress(false));
        });
        return resp;
    },
    error => {
        import('redux/Store').then(Store => {
            if (error && error.response) {
                const errorStatuses = [400, 403, 404, 429, 422];
                const { data, status } = error.response;
                if (status === 500) {
                    ToastService.error(data.message);
                } else if ((status === 401 || status === 421) && data.message) {
                    Store.default.dispatch(setLogout());
                    ToastService.error(data.message);
                } else if (errorStatuses.includes(status) && data.message) {
                    ToastService.warning(data.message);
                }
            }
            Store.default.dispatch(setApiCallProgress(false));
        })
        return Promise.reject(error);
    }
)

export const Http = axios;