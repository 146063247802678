import loadable from '@loadable/component';
import { Loader } from 'components';

const LoginView = loadable(() => import('../views/Auth/Login/LoginView'), { fallback: <Loader /> });
const RegisterViewView = loadable(() => import('../views/Auth/Register/RegisterView'), { fallback: <Loader /> });
const ForgotPasswordView = loadable(() => import('../views/Auth/ForgotPassword/ForgotPasswordView'), { fallback: <Loader /> });
const OtpVerificationView = loadable(() => import('../views/Auth/OtpVerification/OtpVerificationView'), { fallback: <Loader /> });
const ResetPasswordView = loadable(() => import('../views/Auth/ResetPassword/ResetPasswordView'), { fallback: <Loader /> });
const LoginUsingTokenView = loadable(() => import('../views/Auth/LoginUsingToken/LoginUsingTokenView'), { fallback: <Loader /> });

const PrivacyPolicyView = loadable(() => import('../views/Static/PrivacyPolicyView'), { fallback: <Loader /> });
const CookiePolicyView = loadable(() => import('../views/Static/CookiePolicyView'), { fallback: <Loader /> });
const TermsConditionView = loadable(() => import('../views/Static/TermsConditionView'), { fallback: <Loader /> });

export const AuthRoutes = [
    { key: 'login', path: '/login', exact: true, component: LoginView, auth: false },
    { key: 'signup', path: '/signup', exact: true, component: RegisterViewView, auth: false },
    { key: 'otpVerify', path: '/otp-verification', exact: true, component: OtpVerificationView, auth: false },
    { key: 'forgetPassword', path: '/forgot-password', exact: true, component: ForgotPasswordView, auth: false },
    { key: 'resetPassword', path: '/reset-password/:email/:otp', exact: true, component: ResetPasswordView, auth: false },
    { key: 'loginUsingToken', path: '/login-using-token/:token', exact: true, component: LoginUsingTokenView, auth: false }
];

export const StaticRoutes = [
    { key: 'privacyPolicy', path: '/privacy-policy', exact: true, component: PrivacyPolicyView, auth: false },
    { key: 'cookiePolicy', path: '/cookie-policy', exact: true, component: CookiePolicyView, auth: false },
    { key: 'termsCondition', path: '/terms-condition', exact: true, component: TermsConditionView, auth: false },
];