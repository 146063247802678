import React from 'react';
import classNames from 'classnames';
import { withStyles, makeStyles } from '@mui/styles';
import styles from './muiStyles-jss';
import { Button } from "@mui/material";
import Theme from 'Theme';

const MuiButton = (props) => {
  const { iconposition, size, width, background, color, whiteSpace, customclass, btncolor } = props;

  const classes = useStyles();

  return (
    <Button
      {...props}
      className={classNames(classes.commonBtn, classes.primaryButton, background === "false" && classes.noBgPrimaryBtn, background === "grey" && classes.greyBtn, width === "full" && classes.fullWidthBtn, width === "auto" && classes.autoWidthBtn, size === "small" && classes.smallBtn, iconposition === "left" && classes.btnLeftIcon, iconposition === "right" && classes.btnRightIcon, iconposition === "center" && classes.btnCenterIcon, whiteSpace && classes.btnWhiteSpace, btncolor === "primary" && 'primaryColor', customclass)}
    />
  );
}

const useStyles = makeStyles({
  commonBtn: {
    borderRadius: '6px',
    backgroundColor: Theme.palette.primary.main,
    color: Theme.palette.secondary.dark,
    transition: '0.5s',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    maxWidth: 'fit-content',
    minWidth: 130,
    textTransform: 'capitalize',
    position: 'relative',
    boxShadow: 'none !important',
    '& img': {
      width: 22,
      marginRight: 5,
      transition: '0.5s',
    },
    '&:hover, &:focus': {
      backgroundColor: Theme.palette.primary.dark,
      color: Theme.palette.secondary.main,
      transition: '0.5s',
      '& .MuiButton-startIcon, & .MuiButton-endIcon': {
        borderColor: '#505050'
      },
      '& img': {
        filter: 'invert(1)',
        transition: '0.5s',
      },
    },
    '& span': {
      fontFamily: 'Open Sans,sans-serif',
    },
    '&.MuiButton-root': {
      fontWeight: 600,
    },
    '& .MuiButton-startIcon, & .MuiButton-endIcon': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      padding: '0 10px',
      marginLeft: 0,
    },
    '&$btnLeftIcon': {
      paddingLeft: 55,
      '& .MuiButton-startIcon': {
        left: 0,
        marginLeft: 0,
        borderRight: '1px solid #fbbb17',
      },
    },
    '&$btnRightIcon': {
      paddingRight: 55,
      '& .MuiButton-endIcon': {
        right: 0,
        marginRight: 0,
        borderLeft: '1px solid #fbbb17',
      },
    },
    '&$btnCenterIcon': {
      '& .MuiButton-startIcon': {
        position: 'unset',
        padding: 0,
        marginRight: 6,
      },
      '& .MuiButton-endIcon': {
        position: 'unset',
        padding: 0,
        marginLeft: 6,
      },
    },
    '&$smallBtn': {
      paddingTop: 6,
      paddingBottom: 6,
    },
    '&$autoWidthBtn': {
      minWidth: 'auto',
    },
    '&$fullWidthBtn': {
      minWidth: '100%',
      maxWidth: '100%',
    },
    '&.MuiButton-outlined': {
      border: `1px solid ${Theme.palette.secondary.dark}`,
      color: Theme.palette.secondary.dark,
      backgroundColor: Theme.palette.secondary.main,
      '&$btnRightIcon': {
        paddingRight: 35,
      },
      '&$btnLeftIcon': {
        paddingLeft: 35,
      },
      '&:hover, &:focus': {
        border: `1px solid ${Theme.palette.primary.dark}`,
        backgroundColor: Theme.palette.primary.dark,
        color: `${Theme.palette.secondary.main} !important`,
      },
      '& .MuiButton-endIcon, .MuiButton-startIcon': {
        border: 'none !important',
      }
    },
    '&.Mui-disabled': {
      '&$btnLeftIcon': {
        '& .MuiButton-startIcon': {
          borderRight: '1px solid #b9b9b9',
        },
      },
      '&$btnRightIcon': {
        '& .MuiButton-endIcon': {
          borderLeft: '1px solid #b9b9b9',
        },
      },
    }
  },
  btnLeftIcon: {},
  btnRightIcon: {},
  btnCenterIcon: {},
  smallBtn: {},
  autoWidthBtn: {},
  fullWidthBtn: {},
  noBgPrimaryBtn: {
    borderRadius: 0,
    backgroundColor: 'transparent !important',
    color: Theme.palette.primary.main,
    transition: '0.5s',
    // fontSize: '14px !important',
    fontWeight: 600,
    padding: 0,
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    zIndex: 1,
    maxWidth: 'fit-content !important',
    minWidth: 'auto !important',
    textTransform: 'capitalize !important',
    position: 'relative !important',
    boxShadow: 'none !important',
    padding: '0 !important',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      color: Theme.palette.secondary.dark,
    },
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    '& .roundIcon': {
      background: Theme.palette.secondary.dark,
      borderRadius: '50%',
      width: 25,
      height: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 2,
      '& svg': {
        color: Theme.palette.secondary.main,
        fontSize: '20px !important',
      },
    },
    '&.primaryColor': {
      color: Theme.palette.primary.main,
      '& .roundIcon': {
        background: Theme.palette.primary.main,
        transition: '0.5s',
        '& svg': {
          color: Theme.palette.secondary.dark,
          transition: '0.5s',
        },
      },
      '&:hover': {
        color: Theme.palette.secondary.dark,
        '& .roundIcon': {
          background: Theme.palette.secondary.dark,
          transition: '0.5s',
          '& svg': {
            color: Theme.palette.secondary.main,
            transition: '0.5s',
          },
        }
      }
    },
  },
  greyBtn: {
    backgroundColor: `${Theme.palette.primary.light} !important`,
    color: `${Theme.palette.secondary.dark} !important`,
    '&:hover': {
      backgroundColor: `${Theme.palette.secondary.dark} !important`,
      color: `${Theme.palette.secondary.main} !important`,
      '&$btnLeftIcon': {
        '& .MuiButton-startIcon': {
          borderRight: '1px solid #2e2e2e',
        },
      },
      '&$btnRightIcon': {
        '& .MuiButton-endIcon': {
          borderLeft: '1px solid #2e2e2e',
        },
      },
    },
    '&$btnLeftIcon': {
      '& .MuiButton-startIcon': {
        borderRight: '1px solid #dfdfdf',
      },
    },
    '&$btnRightIcon': {
      '& .MuiButton-endIcon': {
        borderLeft: '1px solid #dfdfdf',
      },
    },
  },
  btnWhiteSpace: {
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  }
});

export default withStyles(styles)(MuiButton);