const Quotes = [
    {
        title: 'The golden rule for every businessperson is this: Put yourself in your customer’s place.',
        author: 'Orison Swett Marden'
    },
    {
        title: 'It takes months to find a customer… seconds to lose one.',
        author: 'Vince Lombardi'
    },
    {
        title: 'A satisfied customer is the best business strategy of all.',
        author: 'Michael LeBoeuf'
    }
    ,
    {
        title: 'Everything starts with the customer.',
        author: 'June Martin'
    }
    ,
    {
        title: 'The customer always comes first.',
        author: 'Rajen Jani'
    },
    {
        title: 'Look after the customer and the business will take care of itself.',
        author: 'Ray Kroc'
    },
    {
        title: 'When the customer comes first, the customer will last.',
        author: 'Robert Half'
    },
    {
        title: 'The first step to delighting your customers is being there when they need you.',
        author: 'Ron Kaufman'
    },
    {
        title: 'Only your customers can define quality, because it’s meeting your customer’s expectations the first time every time. Simply put, it’s performance to the standards of the customer.',
        author: 'Ed Robertson'
    }
]
export default Quotes;