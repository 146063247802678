import { AWS_S3_BUCKET_URL } from "configs";

export const formatPricing = (price) => {
    if (!price) return;

    return Number(price) / 100;
}

export const getCroppedImage = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    let fileUrl = '';

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                resolve(blob);
            },
            "image/jpeg",
            1
        );
    });
}

export const getImageUrl = img =>  img && `${AWS_S3_BUCKET_URL}/${img}`;

export const getFullDate = (utcDate) => {
    const formatter = new Intl.DateTimeFormat('en-us', { timeZone: 'UTC', timeStyle: 'medium', dateStyle: 'full' });
    const formatted = formatter.formatToParts(new Date(utcDate));

    let finalDate;
    const date = formatted[4].value;
    let wr;
    if (date > 3 && date < 21) wr = 'th';
    switch (date % 10) {
        case 1: wr = "st";
        case 2: wr = "nd";
        case 3: wr = "rd";
        default: wr = "th";
    }
    finalDate = `${formatted[2].value} ${formatted[4].value}${wr} ${formatted[6].value}, ${formatted[8].value}:${formatted[10].value}:${formatted[12].value} ${formatted[14].value}`
    return finalDate;
}