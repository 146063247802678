export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const UPDATE_USER = 'UPDATE_USER';
export const REGISTER = 'REGISTER';
export const REGISTER_OTP_VERIFY = 'REGISTER_OTP_VERIFY';
export const OTP_SEND = 'OTP_SEND';
export const CHECK_AUTH = 'CHECK_AUTH';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export const setLogin = (data) => {
    return {
        type: LOGGED_IN,
        payload: data
    }
}

export const setLogout = () => {
    return {
        type: LOGGED_OUT,
    }
}

export const setUpdateUser = (data) => {
    return {
        type: UPDATE_USER,
        payload: data
    }
}

export const setRegister = (data) => {
    return {
        type: REGISTER,
        payload: data
    }
}

export const setRegisterOtpVerify = () => {
    return {
        type: REGISTER_OTP_VERIFY,
    }
}

export const setOtpVerify = () => {
    return {
        type: OTP_SEND,
    }
}

export const setCheckAuth = () => {
    return {
        type: CHECK_AUTH
    }
}

export const setUpdateUserStatus = (data) => {
    return {
        type: UPDATE_USER_STATUS,
        payload: data
    }
}

export const setUpdateUserProfile = (data) => {
    return {
        type: UPDATE_USER_PROFILE,
        payload: data
    }
}

export const addNotification = (data) => {
    return {
        type: ADD_NOTIFICATION,
        payload: data
    }
}