import { createTheme } from '@mui/material/styles';

const mulish = "'Mulish', sans-serif"; // Mulish Font
const openSans = "'Open Sans', sans-serif"; // Open sans Font

// start color code global for this theme file also
const primary = { main: '#fbce17', dark: '#292b2a', light: '#dee0e2', contrastText: '#f3f5f9', }
const secondary = { main: '#fff', dark: '#000', light: '#000000de', contrastText: '#f3f6f9bf', }
const error = { main: '#ff0000', dark: '#e65100', light: '#d32f2f', contrastText: '#F57F17', }
const success = { light: '#4caf50', main: '#2e7d32', dark: '#1b5e20', contrastText: '#10d63040' }
const text = { light: '#1c20268c', main: '#000000de', dark: '#000', contrastText: '#e9e9e9', }
// end color code global for this theme file also

const breakpoints = {
	values: {
		xs: 0,
		sm: 768,
		md: 992,
		lg: 1200,
		xl: 1536,
	},
};

const Theme = createTheme({
	palette: {
		primary: {
			main: primary.main,
			dark: primary.dark,
			light: primary.light,
			contrastText: primary.contrastText,
		},
		secondary: {
			main: secondary.main,
			dark: secondary.dark,
			light: secondary.light,
			contrastText: secondary.contrastText,
		},
		error: {
			main: error.main,
			dark: error.dark,
			light: error.light,
			contrastText: error.contrastText,
		},
		success: {
			main: success.main,
			dark: success.dark,
			light: success.light,
			contrastText: success.contrastText,
		},
		text: {
			main: text.main,
			dark: text.dark,
			light: text.light,
			contrastText: text.contrastText,
		},
	},
	typography: {
		h1: {
			fontSize: 30,
			fontWeight: 700,
			fontFamily: mulish,
			color: secondary.dark,
			lineHeight: 'inherit',
			[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
				fontSize: 35
			},
			[`@media screen and (min-width: 1700px)`]: {
				fontSize: 40
			},
			[`@media screen and (max-width: 767px)`]: {
				fontSize: 25
			},
			[`@media screen and (max-width: 575px)`]: {
				fontSize: 22
			}
		},
		h2: {
			fontSize: 25,
			fontWeight: 700,
			fontFamily: mulish,
			color: secondary.dark,
			lineHeight: 'inherit',
			[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
				fontSize: 30
			},
			[`@media screen and (min-width: 1700px)`]: {
				fontSize: 35
			},
			[`@media screen and (max-width: 767px)`]: {
				fontSize: 22
			},
			[`@media screen and (max-width: 575px)`]: {
				fontSize: 20
			}
		},
		h3: {
			fontSize: 20,
			fontWeight: 700,
			fontFamily: mulish,
			color: secondary.dark,
			lineHeight: 'inherit',
			[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
				fontSize: 22
			},
			[`@media screen and (min-width: 1700px)`]: {
				fontSize: 28
			},
			[`@media screen and (max-width: 575px)`]: {
				fontSize: 18
			}
		},
		h4: {
			fontSize: 18,
			fontWeight: 700,
			fontFamily: mulish,
			color: secondary.dark,
			lineHeight: 'inherit',
			[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
				fontSize: 20
			},
			[`@media screen and (min-width: 1700px)`]: {
				fontSize: 24
			},
			[`@media screen and (max-width: 575px)`]: {
				fontSize: 16
			}
		},
		h5: {
			fontSize: 16,
			fontWeight: 700,
			fontFamily: mulish,
			color: secondary.dark,
			lineHeight: 'inherit',
			[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
				fontSize: 18
			},
			[`@media screen and (min-width: 1700px)`]: {
				fontSize: 20
			},
		},
		h6: {
			fontSize: 14,
			fontWeight: 700,
			fontFamily: mulish,
			color: secondary.dark,
			lineHeight: 'inherit',
			[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
				fontSize: 16
			},
			[`@media screen and (min-width: 1700px)`]: {
				fontSize: 18
			}
		},
		body1: {
			textTransform: 'inherit',
			fontFamily: openSans,
			fontSize: 16,
			fontWeight: 400,
			color: text.light,
			lineHeight: 'inherit',
			[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
				fontSize: 18
			},
			[`@media screen and (min-width: 1700px)`]: {
				fontSize: 20
			}
		},
		body2: {
			textTransform: 'inherit',
			fontFamily: openSans,
			fontSize: 16,
			color: secondary.light,
			fontWeight: 400,
			lineHeight: 'inherit',
			[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
				fontSize: 18
			},
			[`@media screen and (min-width: 1700px)`]: {
				fontSize: 20
			}
		},
		body3: {
			textTransform: 'inherit',
			fontFamily: openSans,
			fontSize: 15,
			fontWeight: 400,
			color: text.light,
			lineHeight: 'inherit',
			[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
				fontSize: 17
			},
			[`@media screen and (min-width: 1700px)`]: {
				fontSize: 19
			}
		},
		body4: {
			textTransform: 'inherit',
			fontFamily: openSans,
			fontSize: 14,
			fontWeight: 400,
			color: text.main,
			lineHeight: 'inherit',
			[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
				fontSize: 16
			},
			[`@media screen and (min-width: 1700px)`]: {
				fontSize: 18
			}
		},
	},
	components: {
		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
				size: 'small',
				fontFamily: mulish,
				sx: {
					mb: 2,
					width: '100%',
					'[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button':
					{
						webkitAppearance: 'none',
						mozAppearance: 'none',
						appearance: 'none',
					},
				},
			},
		},
		MuiButton: {
			defaultProps: {
				variant: 'contained',
				sx: {
					py: 1,
					fontWeight: 700,
					fontFamily: mulish,
					fontSize: 16,
					textTransform: 'inherit !important',
					color: secondary.dark,
					[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
						fontSize: 18,
					},
					[`@media screen and (min-width: 1700px)`]: {
						fontSize: 22,
						py: 1.5,
					},
					[`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
						fontSize: 16,
						px: 1.5,
					},
					'& svg.MuiSvgIcon-root': {
						[`@media screen and (min-width: 1700px)`]: {
							fontSize: 30,
						},
					},
					'&.btnLink': {
						fontFamily: mulish,
						fontSize: 16,
						fontWeight: 700,
						transition: '0.5s',
						backgroundColor: 'transparent',
						color: secondary.dark,
						padding: 0,
						verticalAlign: 'baseline',
						lineHeight: 'inherit',
						'&:hover': {
							color: primary.main,
							transition: '0.5s',
						},
						[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
							fontSize: 20,
						},
						[`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
							fontSize: 16,
						},
					}
				},
			},
		},
		MuiLink: {
			defaultProps: {
				underline: 'none',
				display: 'inline-block',
				fontWeight: 700,
				textTransform: 'inherit',
				color: secondary.dark,
				sx: {
					fontFamily: mulish,
					fontSize: 16,
					transition: '0.5s',
					'&:hover': {
						color: primary.main,
						transition: '0.5s',
					},
					[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
						fontSize: 20,
					},
					[`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
						fontSize: 16,
					},
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				sx: {
					py: 1,
					fontWeight: 500,
					fontFamily: openSans,
					fontSize: 16,
					textTransform: 'inherit !important',
					[`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
						fontSize: 18,
					},
					[`@media screen and (min-width: 1700px)`]: {
						fontSize: 20
					},
					[`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
						fontSize: 16,
						px: 1.5,
					},
				},
			},
		},
		MuiTooltip: {
			defaultProps: {
				arrow: true,
				fontFamily: mulish,
			},
		},
	},
});

export default Theme;