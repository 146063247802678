import { Http } from "Http";
import { CHECK_AUTH, LOGGED_IN, LOGGED_OUT, OTP_SEND, REGISTER, REGISTER_OTP_VERIFY, UPDATE_USER, UPDATE_USER_PROFILE, UPDATE_USER_STATUS,FETCH_NOTIFICATIONS,ADD_NOTIFICATION } from "redux/Actions/login";

export const UserStatus = {
    DEFAULT: 'DEFAULT',
    LOGGED_IN: 'LOGGED_IN',
    VERIFIED: 'VERIFIED',
    REGISTERED: 'REGISTERED',
    REGISTER_OTP_VERIFIED: 'REGISTER_OTP_VERIFIED',
}

const initialState = {
    token: '',
    isAuthenticated: false,
    user: { email: '' },
    rememberMe: false,
    userStatus: UserStatus.DEFAULT,
    isAdminUser: false,
};

const Login = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOGGED_IN:
            return {
                ...state,
                user: { email: payload.email },
                rememberMe: payload.rememberMe,
                userStatus: UserStatus.LOGGED_IN,
                token: payload.access_token
            }

        case LOGGED_OUT:
            Http.defaults.headers.common['Authorization'] = '';
            return {
                ...state,
                isAuthenticated: initialState.isAuthenticated,
                user: initialState.user,
                userStatus: initialState.userStatus,
                token: initialState.token
            }

        case UPDATE_USER:
            Http.defaults.headers.common['Authorization'] = `Bearer ${payload.access_token}`;
            return {
                ...state,
                isAuthenticated: payload.access_token && payload.data ? true : false,
                token: payload.access_token,
                user: payload.data,
            }

        case UPDATE_USER_PROFILE:
            return {
                ...state,
                user: { ...state.user, ...payload }
            }

        case CHECK_AUTH:
            if (state.userStatus === UserStatus.VERIFIED || state.userStatus === UserStatus.REGISTER_OTP_VERIFIED) {
                Http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
            }
            return state;

        case REGISTER:
            return {
                ...state,
                userStatus: UserStatus.REGISTERED,
                user: { email: payload.email },
            }

        case REGISTER_OTP_VERIFY:
            return {
                ...state,
                userStatus: UserStatus.REGISTER_OTP_VERIFIED
            }

        case OTP_SEND:
            return {
                ...state,
                userStatus: UserStatus.VERIFIED
            }

        case UPDATE_USER_STATUS:
            return {
                ...state,
                userStatus: payload
            }
        case ADD_NOTIFICATION:
                const user = state.user;
                if(user.notifications){
                user.notifications =[payload,...user.notifications];
                }else{
                user.notifications = [payload]
                }
                return {
                    ...state,
                    user:{...user}
                }

        default:
            return state;
    }
};

export default Login;