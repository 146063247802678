import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle, MuiIconButton, MuiTypography } from 'components/MUI';
import { CloseIcon } from 'helpers/Icons';
import { CommonContent } from 'styledComponent';

const CustomDialog = styled(MuiDialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(4, 3),
        backgroundColor: theme.palette.secondary.contrastText
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2, 3),
        '& button': {
            minWidth: 100,
        }
    },
    '& .MuiPaper-root': {
        minWidth: '50%',
    },
}));

function CustomDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <MuiDialogTitle component='div' sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <MuiIconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </MuiIconButton>
            ) : null}
        </MuiDialogTitle>
    );
}

CustomDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function CommonModal(props) {
    const { handleClose, open, content, title, actions } = props;

    return (
        <CustomDialog
            onClose={handleClose}
            aria-labelledby="dialog-title"
            scroll='body'
            open={open}
        >
            <CustomDialogTitle id="dialog-title" onClose={handleClose}>
                <MuiTypography variant='h4'>{title}</MuiTypography>
            </CustomDialogTitle>

            <CommonContent dividers>
                {content}
            </CommonContent>

            <MuiDialogActions>
                {actions}
            </MuiDialogActions>
        </CustomDialog>
    );
}

export default CommonModal;