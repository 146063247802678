import { ZOHO_WIDGET_CODE } from 'configs';
import React, { Fragment } from 'react'

function ZohoSalesIO() {
    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode: ZOHO_WIDGET_CODE,
        values: {},
        ready: function () { },
    };
    const d = document;
    let s;
    s = d.createElement('script');
    s.type = 'text/javascript';
    s.id = 'zsiqscript';
    s.defer = true;
    s.src = 'https://salesiq.zohopublic.eu/widget';
    let t;
    t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
    return (
        <Fragment>
            <div id='zsiqwidget'></div>
        </Fragment>
    );
}

export default ZohoSalesIO