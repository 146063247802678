import { MuiBox, MuiGrid, MuiHidden, MuiTypography } from 'components/MUI';
import React, { useEffect } from 'react';
import { Logo, Quote, RoundLogo } from 'Assets/Images';
import { Switch, Redirect, Route } from 'react-router-dom';
import { Map } from 'helpers';
import { AuthRoutes } from 'routes/Auth';
import Quotes from 'constants/Quotes';
import { ZOHO_WIDGET_CODE } from 'configs';

function Auth(props) {
    const { classes, location } = props;
    const quoteIndex = Math.floor(Math.random() * Quotes.length);

    useEffect(() => {
        if (location.pathname === "/signup") {
            document.body.className = 'signUpBody'
        } else document.body.className = ''
    }, [location.pathname]);

    return (
        <MuiBox className={classes.authPageMainWrap}>
            <MuiGrid container spacing={0}>
                <MuiGrid item md={6} xs={12}>
                    <MuiBox className="authLeftMain">
                        <MuiBox className="authLogoWrap">
                            <a href="https://evaluationshub.com/" target="_blank">
                                <img src={Logo} alt="logo" />
                            </a>
                        </MuiBox>

                        <MuiBox className="authFormMain">
                            <Switch>
                                {Map(AuthRoutes, (route, routeIndex) => (
                                    <Route key={routeIndex} path={route.path} exact={route.exact}
                                        render={(rProps) => <route.component {...props} {...rProps} />} />
                                ))}
                                <Redirect exact to="/login" />
                            </Switch>
                        </MuiBox>
                    </MuiBox>
                </MuiGrid>
                <MuiHidden mdDown>
                    <MuiGrid item md={6} className="authRightMain">
                        <MuiBox className="authRightContetMain">
                            {/* <img src={RoundLogo} alt="logo" className='roundLogo' /> */}
                            <MuiBox className="content">
                                {/* <MuiTypography variant='h3' fontWeight='800' color='secondary.main' align='center' mb={4}>
                                    Vockam Tools for voice of customer in key account management.
                                </MuiTypography> */}
                                <MuiBox className="whiteBox" align='center'>
                                    <MuiTypography variant='body2'>
                                        <img src={Quote} alt="quote" className='quoteIcon first' />
                                        {Quotes[quoteIndex].title}
                                        <img src={Quote} alt="quote" className='quoteIcon last' />
                                    </MuiTypography>
                                    <MuiTypography variant='h4' mt={2.5}>- {Quotes[quoteIndex].author}</MuiTypography>
                                </MuiBox>
                            </MuiBox>
                        </MuiBox>
                    </MuiGrid>
                </MuiHidden>
            </MuiGrid>
        </MuiBox>
    )
}

export default Auth