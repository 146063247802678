import React from 'react';
import loadable from '@loadable/component';
import { Loader } from 'components';

const ProfileView = loadable(() => import('../views/Application/Profile/ProfileView'), { fallback: <Loader /> });
const PaymentStatusView = loadable(() => import('../views/Application/Plans/PaymentStatusView'), { fallback: <Loader /> });
const PlanListView = loadable(() => import('../views/Application/Plans/PlanListView'), { fallback: <Loader /> })
const PricingStepThreeView = loadable(() => import('../views/Application/Plans/PricingStepThreeView'), { fallback: <Loader /> })
const PricingStepTwoView = loadable(() => import('../views/Application/Plans/PricingStepTwoView'), { fallback: <Loader /> })
const PricingCheckOutView = loadable(() => import('../views/Application/Plans/PricingCheckOutView'), { fallback: <Loader /> })
const MySubscriptionView = loadable(() => import('../views/Application/MySubscription/MySubscriptionView'), { fallback: <Loader /> })
const ThemeSetupView = loadable(() => import('../views/Application/ThemeSetup/ThemeSetup'), { fallback: <Loader /> })
const PricingBusinessUnits = loadable(() => import('../views/Application/PricingBusinessUnits/PricingBusinessUnits'), { fallback: <Loader /> })

export const AppRoutes = [
    // { key: 'dashboard', path: '/dashboard', exact: true, component: DashboardView, auth: true },
    { key: 'profile', path: '/profile', exact: true, component: ProfileView, auth: true },
    { key: 'plans', path: '/plans', exact: true, component: PlanListView, auth: true },
    { key: 'pricingStepthree', path: '/pricing/review/:tenantId', exact: true, component: PricingStepThreeView, auth: true },
    { key: 'paymentStatus', path: '/payment/:status', exact: true, component: PaymentStatusView, auth: true },
    { key: 'pricingStepthree', path: '/pricing/checkout/:tenantId', exact: true, component: PricingCheckOutView, auth: true },
    { key: 'pricingSteptwo', path: '/pricing/:tenantId/:priceId', exact: true, component: PricingStepTwoView, auth: true },
    { key: 'pricingBusinessUnits', path: '/pricing/business-unit', exact: true, component: PricingBusinessUnits, auth: true },
    { key: 'mySubscription', path: '/my-subscription', exact: true, component: MySubscriptionView, auth: true },
    { key: 'themeSetup', path: '/theme', exact: true, component: ThemeSetupView, auth: true },
]