import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from "@mui/material";

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  return <NavLink to={props.to} {...props} innerRef={ref} />;
});

const MuiCustomLink = (props) => {
  return (
    <Link size="small" component={LinkBtn} {...props} style={{ textDecoration: 'none' }} />
  );
}

export default MuiCustomLink;