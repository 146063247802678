import { Http } from "Http";


export default class AuthService {

    static register(params = {}) {
        return new Promise((resolve, reject) => {
            Http.post('/tenant-register', params).then((resp) => {
                return resolve(resp.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    static login(params = {}) {
        return new Promise((resolve, reject) => {
            Http.post('/login', params).then((resp) => {
                return resolve(resp.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    static socialLogin(provider) {
        return new Promise((resolve, reject) => {
            Http.get(`/auth/${provider}/redirect`).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    }

    static resendOtp(params = {}) {
        return new Promise((resolve, reject) => {
            Http.post('/user/resend-otp', params).then((resp) => {
                return resolve(resp.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    static verifyRegisterOtp(params) {
        return new Promise((resolve, reject) => {
            Http.post('/user/verify-seller-register-otp', params).then(res => {
                if (res.data && res.data.status === "success")
                    return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    }

    static logout() {
        return new Promise((resolve, reject) => {
            Http.get('/user/logout').then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    }

    static forgetPassword(params) {
        return new Promise((resolve, reject) => {
            Http.post('/user/forgot-password', params).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    }

    static getMe(params) {
        return new Promise((resolve, reject) => {
            Http.get('/user/me', params).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static resetPassword(params) {
        return new Promise((resolve, reject) => {
            Http.post('user/set-password', params).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static changePassword(params) {
        return new Promise((resolve, reject) => {
            Http.post('/user/password-change', params).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static updateProfile(params) {
        return new Promise((resolve, reject) => {
            Http.post('/user/profile', params).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    static loginUsingToken(params) {
        return new Promise((resolve, reject) => {
            Http.post('/user/login-using-token', params).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    }

    static sendEmailVerificationMail(params) {
        return new Promise((resolve, reject) => {
            Http.post(`/user/send-email-verification`, params).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    }
}
