import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  MuiBox,
  MuiButton,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogTitle,
  MuiIconButton,
  MuiInputField,
  MuiTypography,
} from "components/MUI";
import { CloseIcon } from "helpers/Icons";
import AuthService from "services/AuthService";
import { Map } from "helpers";

const CustomDialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "50px 80px",
    backgroundColor: theme.palette.primary.contrastText,
    [theme.breakpoints.down(768)]: {
      padding: "30px 20px",
    },
    [theme.breakpoints.down(576)]: {
      padding: "20px 15px",
    },
    "& .otpBoxWrap": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      gridGap: 30,
      [theme.breakpoints.up("xl")]: {
        gridGap: 50,
      },
      [theme.breakpoints.up(1800)]: {
        gridGap: 70,
      },
      [theme.breakpoints.down(400)]: {
        gridGap: 15,
      },
      "& .textFieldMainWrap": {
        aspectRatio: 1,
        "& .MuiFormControl-root": {
          width: "100%",
          height: "100%",
          margin: 0,
        },
        "& .MuiInputBase-root": {
          width: "100%",
          height: "100%",
          margin: 0,
          borderRadius: "12px",
          backgroundColor: theme.palette.secondary.main,
          [theme.breakpoints.down("md")]: {
            borderRadius: "8px",
          },
        },
        "& input": {
          height: "calc(100% - 30px)",
          padding: 15,
          textAlign: "center",
          fontSize: 22,
          color: theme.palette.secondary.dark,
          [theme.breakpoints.down("sm")]: {
            fontSize: 18,
          },
        },
      },
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
    "& button": {
      minWidth: 100,
    },
  },
  "& .MuiPaper-root": {
    minWidth: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "calc(100% - 30px) !important",
      margin: 15,
    },
  },
}));

function CustomDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <MuiDialogTitle component="div" sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <MuiIconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </MuiIconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

CustomDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function OtpModal(props) {
  const { onModalClose, open, content, title, user, onClickConfirm } = props;

  const [state, setState] = useState({
    otp: ['', '', '', ''],
    disableResend: false,
    counter: 10,
    submitting: false
  })

  const { otp, counter, disableResend, submitting } = state;
  const myInputRef = useRef([]);

  useEffect(() => {
    if (counter === 0) {
      setState(prev => ({ ...prev, disableResend: false }));
    }
  }, [counter]);

  useEffect(() => {
    handleResendOtp(false, 0);
    const interval = setInterval(() => {
      setState((prev) => ({ ...prev, counter: prev.counter - 1 }));
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0)
      myInputRef.current[index - 1].focus();
  };

  const handleChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setState(prev => ({ ...prev, otp: newOtp, submitting: false }))
    if (e.target.value && index < 3)
      myInputRef.current[index + 1].focus();

  };

  const handleResendOtp = (shouldBlockBtn, count) => {
    const params = { email: user.email };
    AuthService.resendOtp(params).then((resp) => {
      // console.log('resp', resp);
    }).catch((err) => setState((prev) => ({ ...prev, counter: 0, disableResend: false })))

    setState((prev) => ({ ...prev, counter: count, disableResend: shouldBlockBtn }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const hasValues = otp.every((val) => val);
    setState(prev => ({ ...prev, submitting: hasValues ? false : true }));
    if (!hasValues) return;

    onClickConfirm(otp.join(''), hasValues);
  }

  return (
    <CustomDialog
      onClose={onModalClose}
      aria-labelledby="dialog-title"
      scroll="body"
      open={open}
    >
      <CustomDialogTitle id="dialog-title" onClose={onModalClose}>
        <MuiTypography variant="h4">{title}</MuiTypography>
      </CustomDialogTitle>
      <MuiDialogContent dividers>
        <MuiTypography component="p" align="center" mb={3}>{content}</MuiTypography>
        <MuiBox className="otpBoxWrap">
          {Map(otp, (otpBox, index) => (
            <MuiInputField
              sx={{ width: '70px', marginLeft: '10px' }}
              key={index}
              name="otp"
              inputProps={{ maxLength: 1 }}
              value={otpBox}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              inputRef={(el) => (myInputRef.current[index] = el)}
              required
            />
          ))}
        </MuiBox>
        <MuiTypography variant='body1' mt={3} align="center">
          Haven't receive the verification code?
          <MuiButton
            onClick={() => handleResendOtp(true, 10)}
            background='false'
            disabled={disableResend}
            style={{ marginLeft: 8, display: 'inline-block' }}
            customclass='btnLink'
            ml={1}
          >
            Resend {disableResend ? (counter.toString().length > 1 ? `0:${counter} sec` : `0:0${counter} sec`) : null}
          </MuiButton>
        </MuiTypography>
      </MuiDialogContent>

      <MuiDialogActions>
        <MuiButton size="small" background="grey" onClick={onModalClose}>Cancel</MuiButton>
        <MuiButton disabled={submitting} onClick={handleSubmit} size="small">Verify</MuiButton>
      </MuiDialogActions>
    </CustomDialog>
  );
}

export default OtpModal;
