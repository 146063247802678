import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Map } from 'helpers';
import { styled } from '@mui/material/styles';
import styles from 'global-styles';
import { PreDashboardRoutes } from 'routes/PreDashboard';
import { MuiBox, MuiCssBaseline, MuiAppBar, MuiToolbar } from 'components/MUI';
import { Loader, Header } from 'components';
import AuthService from 'services/AuthService';
import {  setUpdateUserProfile } from 'redux/Actions/login';
import Theme from 'Theme';

const drawerWidth = 270;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 10px',
    ...theme.mixins.toolbar,
}));

function PreDashboard(props) {

    const { classes, userStatus, isApiCallProgress, dispatch, window } = props;

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        getMe();
    }, [])

    const getMe = () => {
        AuthService.getMe().then((resp) => {
            dispatch(setUpdateUserProfile(resp.data));
        }).catch((err) => err);
    }

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            {isApiCallProgress && <Loader />}
            <MuiBox className={classes.dashboardLayoutMainWrap} sx={{ display: 'flex' }}>
                <MuiCssBaseline />
                <MuiAppBar
                    className='dashboardHeaderWrap'
                    position="fixed"
                    sx={{
                        width: { md:'100%'  },
                        ml: { md: `${drawerWidth}px` },
                        transition: '0.4s',
                    }}
                >
                    <MuiToolbar>
                        <Header handleDrawerToggle={handleDrawerToggle} open={mobileOpen} />
                    </MuiToolbar>
                </MuiAppBar>
                {/* end header */}

               

                <MuiBox component="main" className='dashboardLayoutMain'
                    sx={{
                        flexGrow: 1,
                        width: { xs: '100%', md: mobileOpen ? `calc(100% - ${drawerWidth}px)` : '100%' },
                        transition: '0.4s',
                    }}
                >
                    <MuiBox className='dashboardContentMainWrap'>
                        <DrawerHeader />

                        <Switch>
                            {Map(PreDashboardRoutes, (route, routeIndex) => (
                                <Route key={routeIndex} path={route.path} exact={route.exact}
                                    render={(rProps) => <route.component {...props} {...rProps} />} />
                            ))}
                            <Redirect exact to="/plans" />
                        </Switch>
                    </MuiBox>
                </MuiBox>
            </MuiBox>
        </>
    );
}

PreDashboard.propTypes = {
    classes: PropTypes.any,
};

export default withStyles(styles)(PreDashboard);
