import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField ,LinearProgress} from "@mui/material";
import { VisibilityIcon, VisibilityOffIcon } from 'helpers/Icons';
import { MuiBox } from 'components/MUI';

const MuiPasswordField = (props) => {

	const { iconStart, helperText,warning, ...rest } = props;

	const [isPasswordShow, setIsPasswordShow] = useState(false);

	const handleShowPassword = () => {
		setIsPasswordShow(!isPasswordShow);
	};

	return (
		<MuiBox className='textFieldMainWrap' sx={{
			'& .MuiInputAdornment-positionStart': {
				margin: '0 12px 0 0',
			},
			'& .MuiInputAdornment-root': {
				marginLeft: 0,
			},
			'& .MuiOutlinedInput-root': {
				borderRadius: '5px',
			},
			'& input': {
				padding: iconStart ? '12px 15px 12px 0' : '12px 15px',
				border: 'none',
			},
			'& .MuiInputBase-root:hover fieldset': {
				borderColor: 'rgba(0, 0, 0, 0.23) !important'
			},
			'& div:hover fieldset': {
				borderColor: 'rgba(0, 0, 0, 0.23) !important'
			},
			'& .MuiInputBase-root': {
				paddingRight: 0,
				'&.Mui-focused': {
					'& fieldset': {
						borderWidth: 1,
					}
				}
			}
		}}>
			<TextField
				{...rest}
				fullWidth={true}
				variant="outlined"
				type={isPasswordShow ? 'text' : 'password'}
				helperText={helperText ? helperText : ''}
				InputProps={{
					...rest,
					startAdornment: iconStart ? (
						<InputAdornment position="start">
							{iconStart}
						</InputAdornment>
					) : null,
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={handleShowPassword}>{isPasswordShow ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>
						</InputAdornment>
					)
				}}
			/>
			{warning && props.value.length > 0 && <span style={{ color: getColor(props.value.length), fontSize: '14px' }}>{getStatus(props.value.length)}</span> }

		</MuiBox>
	);
}

MuiPasswordField.propTypes = {
    warning: PropTypes.bool.isRequired,
};

MuiPasswordField.defaultProps = {
	warning: false,
}

export default MuiPasswordField;


const getColor = (size) => {
	if(size <6){
		return "red";
	}else if(size >= 6 && size < 10){
		return "#ff9800";
	}else{
		return "green";
	}
}
const getStatus = (size) => {
	if(size <6){
		return "weak";
	}else if(size >= 6 && size < 10){
		return "good";
	}else{
		return "strong";
	}
}