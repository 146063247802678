import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle, MuiIconButton, MuiTypography,MuiGrid,MuiInputField,MuiBox,MuiButton } from 'components/MUI';
import { CloseIcon } from 'helpers/Icons';
import { CommonContent } from 'styledComponent';
import { Formik } from 'formik';
import { NgoValidator } from 'validators/validator';
import SubscriptionService from 'services/SubscriptionService';

const CustomDialog = styled(MuiDialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(4, 3),
        backgroundColor: theme.palette.secondary.contrastText
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2, 3),
        '& button': {
            minWidth: 100,
        }
    },
    '& .MuiPaper-root': {
        minWidth: '50%',
    },
}));

function CustomDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <MuiDialogTitle component='div' sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <MuiIconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </MuiIconButton>
            ) : null}
        </MuiDialogTitle>
    );
}

CustomDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function CreateNgoModal(props) {
    const { closeModal, createNgoModalOpen} = props;
    const [state, setState] = React.useState({name:'',email:'',phone:''});

    const handleRegister = (values, actions) => {
        actions.setSubmitting(true);
        SubscriptionService.sendRequestForNgoCoupon(values).then((resp) => {
        }).catch((err) => {
            return err;
        }).finally(() => {
            actions.setSubmitting(false);
            closeModal();
        })
    }
    return (
        <CustomDialog
            onClose={closeModal}
            aria-labelledby="dialog-title"
            scroll='body'
            open={createNgoModalOpen}
        >
            <Formik initialValues={state} validationSchema={NgoValidator} onSubmit={handleRegister}>
                {(props) => (
                    <MuiBox component='form' onSubmit={props.handleSubmit} autoComplete='off'>
                        <CustomDialogTitle id="dialog-title" onClose={closeModal}>
                            <MuiTypography variant='h4'>Create NGO</MuiTypography>
                        </CustomDialogTitle>
                    
                        <CommonContent dividers>
                        <MuiGrid container spacing={3}>
                        <MuiGrid item md={12} sm={12} xs={12} >
                        <MuiInputField
                            name='name'
                            placeholder='Name'
                            value={props.values.name}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={props.touched.name && !!props.errors.name}
                            helperText={props.touched.name ? props.errors.name : ""}
                                />
                        </MuiGrid>

                        <MuiGrid item md={12} sm={12} xs={12}>
                            <MuiInputField
                                    name='email'
                                    placeholder='Email'
                                    value={props.values.email}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.touched.email && !!props.errors.email}
                                    helperText={props.touched.email ? props.errors.email : ""}
                                />
                        </MuiGrid>

                        <MuiGrid item md={12} sm={12} xs={12}>
                            <MuiInputField
                                name='phone'
                                placeholder='Phone'
                                value={props.values.phone}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                error={props.touched.phone && !!props.errors.phone}
                                helperText={props.touched.phone ? props.errors.phone : ""}
                                />
                        </MuiGrid>
                        </MuiGrid>
                        </CommonContent>

                        <MuiDialogActions>
                            <MuiBox display="flex" justifyContent="flex-end">
                                <MuiBox align="left">
                                    <MuiButton variant="contained" onClick={closeModal} background='grey'>
                                        <MuiTypography component="span" >Cancel</MuiTypography>
                                    </MuiButton>
                                </MuiBox>
                                <MuiBox  align="right">
                                    <MuiButton variant="contained" type="submit">
                                        <MuiTypography component="span">Submit</MuiTypography>
                                    </MuiButton>
                                </MuiBox>
                            </MuiBox>
                        </MuiDialogActions>
                    </MuiBox> )}
            </Formik>
        </CustomDialog>
    );
}

export default CreateNgoModal;